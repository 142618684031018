@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

html, #root {
  height:100vh;
}

body {
  color: #0a0a0a;
  overflow: hidden;
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: filter 1s ease-in-out;
  filter: blur(0);
  &.blur {
    filter: blur(5px);
  }
}

a {
  color: currentColor;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
