#quiz-QUIZ_FINAL, #quiz-QUIZ_THEMATIQUE {
  .exerciseText {
    font-size: 18px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: normal;
  }

  .questionText {
    margin: auto 0;

    p {
      margin: 0;
      font-size: 16px;
      color: $mainTextColor;
    }
  }

  .questionContainer {
    padding: 16px;
    border-radius: 4px;
    box-shadow: 3px 2px 24px 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
    display: flex;
    margin-bottom: 40px;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }

  .answers-container {
    display: flex;
    list-style: none;
    padding-left: 40px;
    justify-content: center;
    margin: 12px 0;

    @media only screen and (max-width: 600px) {
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }

  .answer {
    margin: 4px;
    border-radius: 20px;
    border: 1px solid #9ea2aa;
    box-sizing: border-box;
    box-shadow: none;
    color: #9ea2aa;
    cursor: pointer;

    &.selected {
      border: solid 2px $answerSelectedContentColor;
      color: $answerSelectedContentColor;
    }

    &.valid {
      border: solid 2px $answerGood;
      background-color: $answerGood;
      color: white;
    }

    &.selected.valid {
      border: solid 2px $answerGood;
      background-color: transparent;
      color: $answerGood;
    }

    &.selected.invalid {
      border: solid 2px $answerBad;
      background-color: transparent;
      color: $answerBad;
    }

    @media only screen and (max-width: 600px) {
      width: 50%;
    }
  }

  .typography {
    padding: 9px 14px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .44px;
    text-align: center;
  }

  img {
    display: none;
  }
}