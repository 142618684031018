#quiz-ANALYSE {
  .exerciseText {
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: normal;
  }

  .questionText {
    margin: auto 0;

    p {
      margin: 0;
      font-size: 16px;
      font-size: 18px;
      font-weight: 900;
      line-height: 1.89;
      color: $colorAnalyse;
    }
  }

  .questionContainer {
    display: flex;
    margin-bottom: 16px;
    flex-direction: column;
  }

  .answers-container {
    display: flex;
    flex-direction: column;
    margin: 12px 0;
  }

  .answer {
    margin: 4px;
    box-sizing: border-box;
    color: #9ea2aa;
    cursor: pointer;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #9ea2aa;
    box-shadow: 3px 2px 24px 0 rgba(0, 0, 0, 0.09);
    background-color: #fff;

    &.selected {
      border: solid 2px $answerSelectedContentColor;
      color: $answerSelectedContentColor;
    }

    &.valid {
      border: solid 2px $answerGood;
      background-color: $answerGood;
      color: white;
    }

    &.selected.valid {
      border: solid 2px $answerGood;
      background-color: transparent;
      color: $answerGood;
    }

    &.selected.invalid {
      border: solid 2px $answerBad;
      background-color: transparent;
      color: $answerBad;
    }
  }

  .typography {
    padding: 15px 8px;
    font-size: 16px;
    letter-spacing: .44px;
  }

  img {
    display: none;
  }
}
