$primary-color: #75b519;
$secondary-color: #00f2f2;

$green: #77C35E;
$mainTextColor: #1b1b1d;
$secondaryTextColor: #18202f;
$answerContentColor: #9ea2aa;
$answerSelectedContentColor: #263c46;
$answerGood: #7fbc4d;
$answerBad: #ee3547;

// Type quiz
$colorQuizThematique: #006e49;
$colorVideo: #006e49;
$colorCategorisation: #7fbc4d;
$colorFicheInfo: #f39200;
$colorAnalyse: #82368c;
$colorQuizFinal: #e6007e;
