$color-anthracite-cac: #273C45;
$color-grayLight-cac: #f1f4f5;
$color-grayCard-cac: #FCFCFC;
$color-greenRF-cac: #77C35E;
$color-greenDark-cac: #006e49;
$color-greenLight-cac: #7fbc4d;
$color-orange-cac: #f39200;
$color-purple-cac: #82368c;
$color-rose-cac: #e6007e;
$color-yellow-cac: #f9cc00;
$color-black-cac: #18202f;

.FICHE_INFO, .VIDEO, .ANNONCE, .QUIZ_THEMATIQUE, .QUIZ_FINAL, .CATEGORISATION, .ANALYSE {
  img {
    max-width: 100%;
  }
  .precision {
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
  }
}

@media only screen and (min-width: 601px) {
  .FICHE_INFO, .VIDEO, .ANNONCE, .QUIZ_THEMATIQUE, .QUIZ_FINAL, .CATEGORISATION, .ANALYSE {
    div:not(.item) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .item > div {
    padding: 24px;
  }

  .item > *:not(div) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .FICHE_INFO, .VIDEO, .ANNONCE, .QUIZ_THEMATIQUE, .QUIZ_FINAL, .CATEGORISATION, .ANALYSE {
    div:not(.item) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .item > div {
    padding: 12px;
  }

  .item > *:not(div) {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.item {
  background-color: white;
  color: $color-black-cac;
  line-height: 1.44;
  letter-spacing: 0.3px;
}

.FICHE_INFO > .item > h1 {
  color: $color-orange-cac;
}
.FICHE_INFO {
  .precision {
    background: rgba($color: $color-orange-cac, $alpha: 0.4);
  }
}
.VIDEO > .item > h1 {
  color: $color-greenDark-cac;
}
.VIDEO {
  .precision {
    background: rgba($color: $color-greenDark-cac, $alpha: 0.4);
  }
}
.ANNONCE > .item > h1 {
  color: #000000;
}
.ANNONCE {
  .precision {
    background: rgba($color: #000000, $alpha: 0.4);
  }
}
.QUIZ_THEMATIQUE > .item > h1 {
  color: #000000;
}
.QUIZ_THEMATIQUE {
  .precision {
    background: rgba($color: #000000, $alpha: 0.4);
  }
}
.QUIZ_FINAL > .item > h1 {
  color: $color-rose-cac;
}
.QUIZ_FINAL {
  .precision {
    background: rgba($color: $color-rose-cac, $alpha: 0.4);
  }
}
.CATEGORISATION > .item > h1 {
  color: $color-greenLight-cac;
}
.CATEGORISATION {
  .precision {
    background: rgba($color: $color-greenLight-cac, $alpha: 0.4);
  }
}
.ANALYSE > .item > h1 {
  color: $color-purple-cac;
}
.ANALYSE {
  .precision {
    background: rgba($color: $color-purple-cac, $alpha: 0.4);
  }
}

.FICHE_INFO > .item > .summary {
  border-left: 3px solid $color-orange-cac;

  h2 {
    background-color: $color-orange-cac;
  }
}
.VIDEO > .item > .summary {
  border-left: 3px solid $color-greenDark-cac;

  h2 {
    background-color: $color-greenDark-cac;
  }
}
.ANNONCE > .item > .summary {
  border-left: 3px solid #000000;

  h2 {
    background-color: #000000;
  }
}
.QUIZ_THEMATIQUE > .item > .summary {
  border-left: 3px solid #000000;

  h2 {
    background-color: #000000;
  }
}
.QUIZ_FINAL > .item > .summary {
  border-left: 3px solid $color-rose-cac;

  h2 {
    background-color: $color-rose-cac;
  }
}
.CATEGORISATION > .item > .summary {
  border-left: 3px solid $color-greenLight-cac;

  h2 {
    background-color: $color-greenLight-cac;
  }
}
.ANALYSE > .item > .summary {
  border-left: 3px solid $color-purple-cac;

  h2 {
    background-color: $color-purple-cac;
  }
}
.FICHE_INFO > .item {
  h2 {
    display:inline-block;
  }
}

.summary {
  background-color: white;

  h2 {
    color: white;
    width: max-content;
    padding: 0 5px;
  }

  ul > li {
    font-weight: 600;
    color: $color-black-cac;
  }
}

.FICHE_INFO > .item > .content {
  h3 {
    color: $color-orange-cac;
  }
}
.VIDEO > .item > .content {
  h3 {
    color: $color-greenDark-cac;
  }
}
.ANNONCE > .item > .content {
  h3 {
    color: #000000;
  }
}
.QUIZ_THEMATIQUE > .item > .content {
  h3 {
    color: #000000;
  }
}
.QUIZ_FINAL > .item > .content {
  h3 {
    color: $color-rose-cac;
  }
}
.CATEGORISATION > .item > .content {
  h3 {
    color: $color-greenLight-cac;
  }
}
.ANALYSE > .item > .content {
  h3 {
    color: $color-purple-cac;
  }
}

.content {
  color: $color-black-cac;
  background-color: #f1f4f5;
  line-height: 1.44;
  letter-spacing: 0.3px;
}
