.digischool-quiz-player {
  padding: 5px 10px;
  display: inline-block;
  width: 100%;

  .quiz-player {
    display: none;

    &.active {
      display: block;
    }

    .quiz-header {
      z-index: 1;
      position: fixed;
      padding: 0 29px;
      bottom: 10px;
      font-size: 14px;
      line-height: 1.86;
      letter-spacing: 0.3px;
      color: $mainTextColor;
      font-weight: normal;
      margin-top: 0.67em;
      margin-bottom: 0.67em;

      .question-counter {
        position: absolute;
        bottom: 10px;
        width: 250px;
      }
    }

    #quiz-information {
      display: none;
    }

    #question-media-container {
      .medias {
        .media {
          margin-top: 30px;
        }
      }
    }

    .medias {
      .media {
        width: 100%;
        height: auto;

        img {
          width: auto;
          max-width: 100%;
          margin: auto;
          display: block;
        }

        .loadImg {
          margin: 10px 0;
          transition: opacity 1s ease-in-out;
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 1;

          &.loaded {
            opacity: 0;
          }
        }
      }
    }

    .btn-next,
    .btn-explanation-quiz {
      outline: none;
      cursor: pointer;
      width: 56px;
      height: 70px;
      border: none;
      font: 0/0 a;
      text-shadow: none;
      color: transparent;
    }

    .button-wrapper {
      position: absolute;
      bottom: 95px;
      left: 85%;
      z-index: 100;

      .btn-explanation-wrapper {
        position: absolute;
        right: 70px;
      }

      .btn-next-wrapper {
        position: absolute;
        right: 0;
      }


    }

    .clear {
      &.result {
        h4 {
          display: none;
        }

        position: fixed;
        bottom: 25px;
        z-index: 101;
        left: 50%;
        margin-left: -400px;
        width: 620px;
        text-align: right;
      }

    }
  }
}

.rating {
  height: 70px;
}

.ReactModal__Content {
  max-width: 620px;
  margin: auto;
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  text-align: center;

  &:not(.quiz-player-explanation) {
    max-width: 90%;
  }

  h5 {
    &.modal-explanation-title {
      font-size: 24px;
      text-align: center;
      font-weight: 800;
      color: #79a611;
    }
  }

  .modal-explanation-btn-close {
    max-width: 150px;
    margin: auto;
    border-radius: 5px;
    width: 100%;
    padding: 12px 0;
    color: white;
    transition: all 0.2s linear;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    background: $green;
  }
}

.progress-bar {
  bottom: 0;
  position: absolute;
  width: 250px;
  height: 6px;

  .progress-bar-background {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    background-color: #ebedee;

    .progress-bar-line {
      height: 100%;
      background-color: $green;
    }
  }
}

.quiz-content {
  padding-bottom: 75px;
}