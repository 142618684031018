.alert {
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.5;
    border-radius: 4px;
    padding: 15px;
    margin: 15px;
    border: 1px solid transparent;
    border-left: 5px solid transparent;
}

.alert-error {
    color: rgb(97, 26, 21);
    background: rgb(253, 236, 234);
    border-color: rgb(97, 26, 21);
}